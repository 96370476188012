.fondoLogo{
    background-color: white !important;
    width: 55px;
    height: 55px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fondoLogo:hover{
    box-shadow: 0px 0px 5px grey;
    cursor: pointer;
}

.logoItem{
    margin: auto auto !important;
}