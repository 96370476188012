
  div#form-box {
    width: 100%;
    position: absolute;
    top: 180px;
    text-align: center;
    color: white;
  }
  
  div#input-group {
    width: 40%;
    
    margin: 0 auto 20px;
    
    position: relative;
  
    background-color: #fff;
    
    border: none;
    border-radius: 15px;
  }
  
  
  input#email, label[for="email"] {
    display:inline-block;
    vertical-align: middle;
  }

  h1 {
      opacity: 1 !important;
  }

  
  input#email {
    padding: 25px;
    width: 40%;
    border: 1px solid;
    border-radius: 15px;
  }

  .land-tarjetas {
    padding: 0 10%;
    margin: 100px 0;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    margin-top: -6rem;

    .landCard {
      border-radius: 10px;
      min-height: 400px;
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }

    .cardText{
      font-family: "Avenir-Next";
      font-size: 15px;
      color: #808080;
    }


  }

  .textGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    text-align: left;

    li {
      margin: 10px;
    }
  }

  .divImage {
    width: 30%;
  }

  div.image-scroll {
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
    color: white;
  }


  @media (max-width: 1200px) {

    .land-tarjetas {
      flex-direction: row;
      

      .landCard {
       margin: 0 10px 0 10px ;
      }
    }

    #landImage {
      min-height: 500px;
      width: 100vh;
    } 

    input#email {
      width: 60%;
    }
  }

  //diseño mobile
  @media (max-width: 600px) {

    .land-tarjetas {
      flex-direction: column;
      

      .landCard {
        margin: 20px 0 20px 0;
      }
    }

    #landImage {
      min-height: 500px;
      width: 100vh;
    } 

    input#email {
      width: 60%;
    }
  }

  @media (max-height: 850px) {
    .landcap{
      height: 500px !important;
      min-height: 500px !important;
    }
    #form-box{
      top: 100px !important;
    }
  }