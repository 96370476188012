.buttonArticulo:hover {
    color: rgb(59, 59, 59) !important;
}

.buttonArticulo {
    font-family: "HelveticaNeue-BoldCondensed" !important;

}

.button-no-PDF {
    font-family: "HelveticaNeue-BoldCondensed" !important;
}

.button-no-PDF:hover {
    cursor: default;
}

.cardArticulo {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
    border-radius: 15px !important;
}

.cardCateg {
    box-shadow: none !important;
    border-radius: 15px !important;
    height: 240px !important;
}

.cardCateg:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
}

.textoRef {
    color: #000000 !important;
}