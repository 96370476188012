

.css-tuqgnz-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: rgb(51, 51, 51) !important;
}


.css-13cwhhk.Mui-selected {
    color: rgb(51, 51, 51) !important;
}

.css-13cwhhk {
    font-family:"Avenir-Next" !important;
}

.css-tuqgnz-MuiButtonBase-root-MuiTab-root{
    font-family:"Avenir-Next" !important;
}


@media (max-width:780px) {
    .tab-pan {
        width: 100% !important;
    }
    .css-19kzrtu {
        padding: 24px 0 !important;
    }
}