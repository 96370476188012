body {
  margin: 0;
  font-family:  'HelveticaNeue-BoldCondensed';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "HelveticaNeue-BoldCondensed";
  src: local("HelveticaNeue-BoldCondensed"),
   url("./assets/fonts/HelveticaNeue-BoldCond.otf") format("truetype");
  }

@font-face {  
  font-family: "Avenir-Next";
  src: local("Avenir-Nex"),
    url("./assets/fonts/AvenirNext-Regular.ttf") format("truetype");
  }