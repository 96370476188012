.knowCard {

    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    color: white !important;
    border-radius: 15px !important;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.knowCard:hover {
    box-shadow: 0px 0px 15px grey !important;
    cursor: pointer;
}

.articCard {
    color: white;
    border-radius: 15px !important;
    border: 1px solid #F2F2F2 !important;
}

.logoKnow{
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoItem:hover{
    cursor: pointer;
}

