.landtitle {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    
  }

  div#form-box2 {
    width: 100%;
    position: absolute;
    bottom: 60vh;
    text-align: center;
    color: white;
  }

  div#form-box2 {
    width: 100%;
    position: absolute;
    bottom: 60vh;
    text-align: center;
    color: white;
  }

  .aaa{
    overflow-wrap: "normal" !important 
  }