.contentContact {
    margin: 0 24em 8rem 24em
}

@media (max-width: 1200px) {
    .contentContact {
        margin: 0 2em 8rem 2em
    }
}


.logoItem {
    margin: auto auto !important;
    width: 32px !important;
}

.logoItem:hover {
    cursor: pointer;
}