.textField {
    width: 100%;
    margin: 0 0 0.4rem 0 !important;
}

.custom-input-file {
    background-color: #d1d1d1;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    margin: 0 auto 0;
    min-height: 15px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    text-align: center;
}

.custom-input-file .input-file {
    border: 10000px solid transparent;
    cursor: pointer;
    font-size: 10000px;
    margin: 0;
    opacity: 0;
    outline: 0 none;
    padding: 0;
    position: absolute;
}

.w-100 {
    min-width: 100% !important;
}

.redColor {
    margin: 0 0;
    color: rgb(255, 100, 100) !important;
    font-size: 14px;
    text-align: left !important;
    font-family: "Avenir-Next";
}

.errorMessage {
    text-align: left !important;
}

.elHover:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    opacity: 0.9;
}

@media (max-width: 1230px) {
    .imgResp{
      height: 170px !important;
    }
  }

@media (max-width: 900px) {
    .imgResp{
      height: 220px !important;
    }
  }