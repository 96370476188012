.footer{
    background-color: #222429;
    left: 0;
    color: #fff;
    width: 100%;
    text-align: center;
    font-family: "Avenir-Next";
    padding: 2rem 0 0 0;
  }

.columnsFooter{
  display: flex;
  flex-direction: column;
  align-items: center;
  h4{
    margin: 0.4rem 0 0.4rem 0 !important;
  }
  p{
    color: #bdbdbd !important;
  }
}

.linksFooter{
  margin: 0.2rem 0 !important;
}