.fondo {
    flex: 1;
    flex-direction: row;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    .login {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        border-radius: 20px !important;
        min-width: 400px;

        .loginCard {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 7% 10% 10% 10%;
            width: 90%;
        
            button {
                margin: 20px !important;
            }
        }
    }
}



