//Empresa color
.header2 {
    position: fixed !important;
    background-color: #f34d47 !important;

    button {
        color: #fff !important;
    }

    transform: translateY(0);
    transition: transform 0.6s ease;

}

//Empresa Intex
.header3 {
    position: fixed !important;
    background-color: #318daa !important;

    button {
        color: #fff !important;
    }

    transform: translateY(0);
    transition: transform 0.6s ease;
}

.header4 {
    position: fixed !important;
    background-color: #FFC300 !important;

    button {
        color: black !important;
    }

    transform: translateY(0);
    transition: transform 0.6s ease;

}

.header {
    position: fixed !important;
    background-color: transparent !important;
    color: #fff !important;
    box-shadow: none !important;

    button {
        color: #fff !important;
    }

    transform: translateY(0);
    transition: transform 0.6s ease;
}

@media (max-width: 600px) {
    #logo {
        display: none !important;
    }

}

@media (min-width: 1200px) {
    .toolbarHeader {
        margin: 0 8rem;
    }
}

@media (max-width: 899px) {
    #logoMarca {
        display: none !important;
    }
}

@media (min-width: 900px) {
    #logoMarcaResp {
        display: none !important;
    }
}